import {
    Divider,
    HStack,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    useMediaQuery,
} from '@chakra-ui/react'
import { ReactNode, FC } from 'react'

interface Props {
    title?: string
    body?: ReactNode | string
    footer?: ReactNode | string
    isOpen: boolean
    onClose: () => void
    size?: string
    isHeaderVisible?: boolean | undefined
    isFooterVisible?: boolean | undefined
    toBottom?: boolean
    showOverlay?: boolean
    showFrom?: 'scale' | 'none' | 'slideInBottom' | 'slideInTop'
    isCentered?: boolean
    isFullScreen?: boolean
    children?: ReactNode | string
    isSideBar?: boolean
    isDesktop?: boolean
}

export const CustomFullScreenModal: FC<Props> = ({
    title,
    body,
    footer,
    isOpen,
    onClose,
    size,
    isHeaderVisible = true,
    isFooterVisible = true,
    toBottom = false,
    showOverlay = true,
    showFrom = 'scale',
    isCentered = false,
    isFullScreen = false,
    children,
    isSideBar = false,
    isDesktop = false,
}) => {
    if (isSideBar && isDesktop) {
        return <>{children}</>
    }

    return (
        <Modal
            isOpen={isOpen}
            scrollBehavior={isDesktop ? 'outside' : 'inside'}
            onClose={onClose}
            size={size}
            // @ts-ignore
            motionPreset={showFrom}
            isCentered={true}
            autoFocus={false}
            blockScrollOnMount={false}
        >
            <ModalOverlay hidden={!showOverlay} />
            <ModalContent
                maxW={isFullScreen ? '100vw' : 'none'}
                alignSelf={toBottom ? 'flex-end' : 'center'}
                marginBottom={toBottom ? 0 : 'auto'}
                position={'relative'}
            >
                {isHeaderVisible ? (
                    <>
                        <ModalHeader>
                            <HStack>
                                <Text flex={1}>{title}</Text>
                                <ModalCloseButton top={4} />
                            </HStack>
                        </ModalHeader>

                        <Divider />
                    </>
                ) : null}

                <ModalBody
                    p={0}
                    // borderRadius={'8px'}
                    border={toBottom ? '1px solid #e0e0e0' : 'none'}
                    boxShadow={toBottom ? '0px -8px 40px 0px rgba(0, 0, 0, 0.08)' : 'none'}
                >
                    {children || body}
                </ModalBody>

                {isFooterVisible ? (
                    <>
                        <Divider />

                        <ModalFooter>{footer}</ModalFooter>
                    </>
                ) : null}
            </ModalContent>
        </Modal>
    )
}
