import { Button, ButtonProps, Text } from '@chakra-ui/react'
import { FC, ReactNode } from 'react'

interface Props extends ButtonProps {
    title?: string
    view: 'primary' | 'secondary' | 'error' | 'success' | 'ghost'
    size: 'sm' | 'md' | 'lg' | 'xs'
    children?: ReactNode
}

export const MainButton: FC<Props> = ({ title, view, size, children, ...props }): JSX.Element | null => {
    const getSize = () => {
        switch (size) {
            case 'xs':
                return '28px'
            case 'sm':
                return '36px'
            case 'md':
                return '40px'
            case 'lg':
                return '44px'
            default:
                return '40px'
        }
    }

    const defaultProps = {
        variant: 'unstyled',
        minW: '80px',
        h: getSize(),
        borderRadius: '6px',
        fontSize: 'sm',
        fontWeight: 'medium',
        letterSpacing: '0.16px',
        padding: '12px 20px',
        lineHeight: '20px',
        display: 'flex',
        alignItems: 'center',
        transition: 'all .3s ease',
        _focus: { boxShadow: '0 0 0 2px #245AF066' },
        _disabled: {
            opacity: 0.5,
            bg: '#F5F5F5',
            color: '#0C0C0D',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            svg: { color: '#0C0C0D' },
        },
    }

    if (view === 'primary') {
        return (
            <Button
                bg={'#245AF0'}
                color={'#FFF'}
                _hover={{ bg: '#1D48C0' }}
                _active={{ bg: '#163690' }}
                {...defaultProps}
                {...props}
            >
                <Text>{title}</Text>
            </Button>
        )
    } else if (view === 'secondary') {
        return (
            <Button
                border={'1px solid #CBCBCD'}
                bg={'#FFF'}
                color={'#0C0C0D'}
                _hover={{ bg: '#F5F5F5' }}
                _active={{ bg: '#E0E0E1' }}
                {...defaultProps}
                {...props}
            >
                {title}
            </Button>
        )
    } else if (view === 'error') {
        return (
            <Button
                bg={'#DB3333'}
                color={'#FFF'}
                _hover={{ bg: '#B62626' }}
                _active={{ bg: '#881C1C' }}
                {...defaultProps}
                {...props}
            >
                {title}
            </Button>
        )
    } else if (view === 'success') {
        return (
            <Button
                bg={'#00853D'}
                color={'#FFF'}
                _hover={{ bg: '#006D33' }}
                _active={{ bg: '#005226' }}
                {...defaultProps}
                {...props}
            >
                {title}
            </Button>
        )
    } else if (view === 'ghost') {
        return (
            <Button
                bg={'transparent'}
                borderColor={'transparent'}
                color={'#0C0C0D'}
                _hover={{ bg: '#F5F5F5' }}
                _active={{ bg: '#E0E0E1' }}
                {...defaultProps}
                {...props}
            >
                {title && title}
                {children}
            </Button>
        )
    } else {
        return null
    }
}
