import {
    Avatar,
    Box,
    Button,
    Flex,
    IconButton,
    Popover,
    PopoverBody,
    PopoverContent,
    PopoverFooter,
    PopoverHeader,
    PopoverTrigger,
    Text,
} from '@chakra-ui/react'
import { useLogoutMutation } from '@core/app/api/authApi'
import { useGetHeaderInfoQuery } from '@core/app/api/profileApi'
import { setResetForm } from '@core/app/slices/profile'
import { MainButton } from '@core/components/MainButton'
import { MenuBurgerIcon } from '@core/components/Sidebar/Icons'
import { useAppDispatch } from '@core/utils/hooks/hook'
import { FC, useState } from 'react'
import { useLocalStorage } from 'usehooks-ts'

interface Props {
    isFixed?: boolean
}

export const EmployeeHeader: FC<Props> = ({ isFixed = 'false' }): JSX.Element => {
    const dispatch = useAppDispatch()
    const { data } = useGetHeaderInfoQuery({})
    const [logout] = useLogoutMutation()
    const [value, setValue] = useLocalStorage('isSidebarOpened', true)
    const [popoverOpened, setPopoverOpened] = useState(false)
    const nameLabel = `${data?.data.FirstName} ${data?.data.LastName}`
    const handleToggle = () => {
        setValue(!value)
    }

    const handleLogout = async () => {
        try {
            const response = await logout().unwrap()
            localStorage.removeItem('mobile_verification')

            location.href = response.redirectUrl
            localStorage.removeItem('apply_dialog')
            localStorage.removeItem('service_code')
            localStorage.removeItem('is_logged')
            localStorage.removeItem('after_apply')
            localStorage.removeItem('applying_process')
            localStorage.removeItem('after_apply_id')
            localStorage.removeItem('after_apply_title')
            dispatch(setResetForm(true))
        } catch (error) {}
    }
    return (
        <Box
            w={'full'}
            h={'56px'}
            bg={'#FFFFFF'}
            px={'12px'}
            py={'14px'}
            borderBottom={'1px solid'}
            borderColor={'border.3'}
            pos={isFixed ? 'fixed' : 'sticky'}
            top={0}
            left={'0'}
            zIndex={1004}
        >
            <Flex alignItems={'center'} w={'full'} justifyContent={'space-between'}>
                <IconButton
                    color={'icons.primary'}
                    w={'32px'}
                    minW={'32px'}
                    h={'32px'}
                    background={'none'}
                    _hover={{
                        background: 'none',
                        color: 'icons.main',
                    }}
                    borderRadius={'6px'}
                    icon={<MenuBurgerIcon boxSize={'20px'} color={'#4b4B4B'} />}
                    aria-label={'collapse sidebar'}
                    onClick={handleToggle}
                />

                <Popover
                    isOpen={popoverOpened}
                    onOpen={() => setPopoverOpened(true)}
                    onClose={() => setPopoverOpened(false)}
                >
                    <PopoverTrigger>
                        <Button minW={'32px'} h={'32px'} borderRadius={'full'} p={0} variant={'unstyled'} size={'lg'}>
                            <Avatar w={'32px'} h={'32px'} src={data?.data.ProfilePicture} />
                        </Button>
                    </PopoverTrigger>
                    <PopoverContent w={'296px'} maxW={'296px'} boxShadow={'0px 8px 24px 0px #9DA0A429'} mr={'12px'}>
                        <PopoverHeader borderBottom={'none'}>
                            <Flex
                                flexDirection={'column'}
                                gap={'12px'}
                                alignItems={'center'}
                                p={'16px'}
                                w={'full'}
                                h={'full'}
                            >
                                <Avatar w={'80px'} h={'80px'} src={data?.data.ProfilePicture} />
                                <Flex
                                    color={'#0C0C0D'}
                                    flexDirection={'column'}
                                    alignItems={'center'}
                                    justifyContent={'center'}
                                    w={'full'}
                                >
                                    <Text
                                        fontWeight={'semibold'}
                                        lineHeight={'24px'}
                                        w={'full'}
                                        textAlign={'center'}
                                        noOfLines={2}
                                    >
                                        {nameLabel}
                                    </Text>
                                    <Text fontSize={'sm'} fontWeight={'medium'}>
                                        {data?.data.Title}
                                    </Text>
                                </Flex>
                            </Flex>
                        </PopoverHeader>
                        <PopoverBody px={'8px'} py={0} borderBottom={'none'}>
                            <Flex
                                borderTop={'1px solid #EAEAEB'}
                                borderBottom={'1px solid #EAEAEB'}
                                flexDirection={'column'}
                                justifyContent={'flex-start'}
                                alignItems={'flex-start'}
                                gap={'6px'}
                                py={'12px'}
                            >
                                <MainButton
                                    w={'full'}
                                    view={'ghost'}
                                    size={'md'}
                                    title={'Profile'}
                                    justifyContent={'flex-start'}
                                    leftIcon={<i className="fa-light fa-user fa-md" />}
                                    _hover={{ bg: '#F5F5F5' }}
                                    transition={'all .3s ease'}
                                    pl={'21px'}
                                    onClick={() => (location.href = '/employee')}
                                />
                                <MainButton
                                    w={'full'}
                                    view={'ghost'}
                                    size={'md'}
                                    title={'Settings'}
                                    justifyContent={'flex-start'}
                                    leftIcon={<i className="fa-light fa-gear fa-md" />}
                                    _hover={{ bg: '#F5F5F5' }}
                                    transition={'all .3s ease'}
                                    onClick={() => (location.href = '/profile/settings')}
                                />
                            </Flex>
                        </PopoverBody>
                        <PopoverFooter px={'8px'} py={'12px'} border={'none'}>
                            <MainButton
                                w={'full'}
                                view={'ghost'}
                                size={'md'}
                                title={'Log out'}
                                justifyContent={'flex-start'}
                                leftIcon={<i className="fa-light fa-arrow-right-from-bracket fa-md" />}
                                _hover={{ bg: '#FCEAEA', color: '#B62626' }}
                                transition={'all .3s ease'}
                                onClick={handleLogout}
                            />
                        </PopoverFooter>
                    </PopoverContent>
                </Popover>
            </Flex>
        </Box>
    )
}
