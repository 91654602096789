import { Checkbox, Flex, Text } from '@chakra-ui/react'
import React, { ChangeEvent, useState } from 'react'

type CheckboxItemType = 'country' | 'region' | 'city' | 'industry' | 'jobType' | 'workType' | 'office'

interface Props {
    id: number | string
    name: string
    selected?: boolean
    type: CheckboxItemType
    onSelect: (id: number | string, type: CheckboxItemType, value: boolean) => void
    isIndeterminate?: boolean
    fontSize?: string
    fontWeight?: string
    children?: React.ReactNode
    size?: 'sm' | 'md' | 'lg' | 'xl'
}

export const CheckboxItem = ({
    id,
    name,
    selected,
    type,
    onSelect,
    isIndeterminate,
    fontSize,
    fontWeight,
    children,
    size,
}: Props): JSX.Element => {
    // const [checked, setChecked] = useState(selected)
    const fontWeightValue = fontWeight || (selected ? 'medium' : 'normal')

    return (
        <Checkbox
            id={`${id}`}
            // isChecked={selected || checked}
            size={size}
            isChecked={selected}
            isIndeterminate={isIndeterminate}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                // setChecked(e.target.checked)
                onSelect(id, type, e.target.checked)
            }}
            name={type}
            _hover={{
                '& > span:first-of-type': {
                    borderColor: 'blue.500',
                },
            }}
        >
            <Flex gap={2}>
                {children}
                <Text
                    fontSize={fontSize || 'sm'}
                    color={selected ? 'text.main' : 'text.secondary'}
                    fontWeight={fontWeightValue}
                    lineHeight={4}
                >
                    {name}
                </Text>
            </Flex>
        </Checkbox>
    )
}
