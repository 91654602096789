import { createIcon } from '@chakra-ui/icon'

export const AbsenceIcon = createIcon({
    displayName: 'AbsenceIcon',
    viewBox: '0 0 20 20',
    path: (
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.5013 0.916504C6.16661 0.916504 5.08464 1.99848 5.08464 3.33317V4.72872H3.33464C1.99995 4.72872 0.917969 5.8107 0.917969 7.14539V16.6665C0.917969 18.0012 1.99995 19.0832 3.33464 19.0832H16.668C18.0027 19.0832 19.0846 18.0012 19.0846 16.6665V7.14539C19.0846 5.8107 18.0027 4.72872 16.668 4.72872H14.918V3.33317C14.918 1.99848 13.836 0.916504 12.5013 0.916504H7.5013ZM13.418 4.72872H6.58464V3.33317C6.58464 2.82691 6.99504 2.4165 7.5013 2.4165H12.5013C13.0076 2.4165 13.418 2.82691 13.418 3.33317V4.72872ZM2.41797 7.14539C2.41797 6.63913 2.82838 6.22872 3.33464 6.22872H5.83464H14.168H16.668C17.1742 6.22872 17.5846 6.63913 17.5846 7.14539V16.6665C17.5846 17.1728 17.1742 17.5832 16.668 17.5832H3.33464C2.82837 17.5832 2.41797 17.1728 2.41797 16.6665V7.14539ZM10.7513 9.1665C10.7513 8.75229 10.4155 8.4165 10.0013 8.4165C9.58709 8.4165 9.2513 8.75229 9.2513 9.1665V10.8303H7.5013C7.08709 10.8303 6.7513 11.1661 6.7513 11.5803C6.7513 11.9945 7.08709 12.3303 7.5013 12.3303H9.2513V14.1665C9.2513 14.5807 9.58709 14.9165 10.0013 14.9165C10.4155 14.9165 10.7513 14.5807 10.7513 14.1665V12.3303H12.5013C12.9155 12.3303 13.2513 11.9945 13.2513 11.5803C13.2513 11.1661 12.9155 10.8303 12.5013 10.8303H10.7513V9.1665Z"
            fill="currentColor"
        />
    ),
})

export const AbsenceIconHovered = createIcon({
    displayName: 'AbsenceIconHovered',
    viewBox: '0 0 20 20',
    path: (
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.79167 3.33333C4.79167 1.83756 6.00423 0.625 7.5 0.625H12.5C13.9958 0.625 15.2083 1.83756 15.2083 3.33333V4.43722H16.6667C18.1624 4.43722 19.375 5.64978 19.375 7.14555V16.6667C19.375 18.1624 18.1624 19.375 16.6667 19.375H3.33333C1.83756 19.375 0.625 18.1624 0.625 16.6667V7.14555C0.625 5.64978 1.83756 4.43722 3.33333 4.43722H4.79167V3.33333ZM13.125 4.43722H6.875V3.33333C6.875 2.98816 7.15482 2.70833 7.5 2.70833H12.5C12.8452 2.70833 13.125 2.98816 13.125 3.33333V4.43722ZM10.75 9.16667C10.75 8.75245 10.4142 8.41667 10 8.41667C9.58579 8.41667 9.25 8.75245 9.25 9.16667V10.8305H7.5C7.08579 10.8305 6.75 11.1662 6.75 11.5805C6.75 11.9947 7.08579 12.3305 7.5 12.3305H9.25V14.1667C9.25 14.5809 9.58579 14.9167 10 14.9167C10.4142 14.9167 10.75 14.5809 10.75 14.1667V12.3305H12.5C12.9142 12.3305 13.25 11.9947 13.25 11.5805C13.25 11.1662 12.9142 10.8305 12.5 10.8305H10.75V9.16667Z"
            fill="#1D48C0"
        />
    ),
})

export const LoggedWorkIcon = createIcon({
    displayName: 'LoggedWorkIcon',
    viewBox: '0 0 20 20',
    path: (
        <>
            <g clipPath="url(#clip0_35835_205637)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.08464 3.33464C5.08464 1.99995 6.16661 0.917969 7.5013 0.917969H12.5013C13.836 0.917969 14.918 1.99995 14.918 3.33464V4.73019H16.668C18.0027 4.73019 19.0846 5.81217 19.0846 7.14685V8.53935C18.649 8.02077 18.1443 7.56198 17.5846 7.17712V7.14685C17.5846 6.64059 17.1742 6.23019 16.668 6.23019H3.33464C2.82837 6.23019 2.41797 6.64059 2.41797 7.14685V16.668C2.41797 17.1742 2.82837 17.5846 3.33464 17.5846H7.1514C7.5371 18.1443 7.99709 18.6491 8.51716 19.0846H3.33464C1.99995 19.0846 0.917969 18.0027 0.917969 16.668V7.14685C0.917969 5.81217 1.99995 4.73019 3.33464 4.73019H5.08464V3.33464ZM6.58464 4.73019H13.418V3.33464C13.418 2.82837 13.0076 2.41797 12.5013 2.41797H7.5013C6.99504 2.41797 6.58464 2.82837 6.58464 3.33464V4.73019ZM13.3346 9.08463C10.9874 9.08463 9.08463 10.9874 9.08463 13.3346C9.08463 15.6818 10.9874 17.5846 13.3346 17.5846C15.6818 17.5846 17.5846 15.6818 17.5846 13.3346C17.5846 10.9874 15.6818 9.08463 13.3346 9.08463ZM7.58464 13.3346C7.58464 10.159 10.159 7.58464 13.3346 7.58464C16.5103 7.58464 19.0846 10.159 19.0846 13.3346C19.0846 16.5103 16.5103 19.0846 13.3346 19.0846C10.159 19.0846 7.58464 16.5103 7.58464 13.3346ZM13.3346 10.0846C13.7488 10.0846 14.0846 10.4204 14.0846 10.8346V13.1544L15.5058 14.4463C15.8123 14.725 15.8349 15.1993 15.5563 15.5058C15.2776 15.8123 14.8033 15.8349 14.4968 15.5563L12.8301 14.0411C12.6738 13.899 12.5846 13.6975 12.5846 13.4861V10.8346C12.5846 10.4204 12.9204 10.0846 13.3346 10.0846Z"
                    fill="currentColor"
                />
            </g>
            <defs>
                <clipPath id="clip0_35835_205637">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </>
    ),
})

export const LoggedWorkIconHovered = createIcon({
    displayName: 'LoggedWorkIconHovered',
    viewBox: '0 0 20 20',
    path: (
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.08464 2.83268C5.08464 1.49799 6.16661 0.416016 7.5013 0.416016H12.5013C13.836 0.416016 14.918 1.498 14.918 2.83268V4.22823H16.668C18.0027 4.22823 19.0846 5.31021 19.0846 6.6449V8.84391C17.7088 7.20607 15.6437 6.16471 13.335 6.16471C9.19282 6.16471 5.83496 9.51658 5.83496 13.6513C5.83496 15.5401 6.53567 17.2655 7.69175 18.5827H3.33464C1.99995 18.5827 0.917969 17.5007 0.917969 16.166V6.6449C0.917969 5.31021 1.99995 4.22823 3.33464 4.22823H5.08464V2.83268ZM6.58464 4.22823H13.418V2.83268C13.418 2.32642 13.0076 1.91602 12.5013 1.91602H7.5013C6.99504 1.91602 6.58464 2.32642 6.58464 2.83268V4.22823ZM7.58464 13.666C7.58464 10.4904 10.159 7.91602 13.3346 7.91602C16.5103 7.91602 19.0846 10.4904 19.0846 13.666C19.0846 16.8417 16.5103 19.416 13.3346 19.416C10.159 19.416 7.58464 16.8417 7.58464 13.666ZM13.3346 10.4147C13.7488 10.4147 14.0846 10.7505 14.0846 11.1647V13.4845L15.5058 14.7764C15.8123 15.0551 15.8349 15.5294 15.5563 15.8359C15.2776 16.1424 14.8033 16.165 14.4968 15.8863L12.8301 14.3712C12.6738 14.229 12.5846 14.0275 12.5846 13.8162V11.1647C12.5846 10.7505 12.9204 10.4147 13.3346 10.4147Z"
            fill="#1D48C0"
        />
    ),
})

export const RequestIcon = createIcon({
    displayName: 'LoggedWorkIcon',
    viewBox: '0 0 20 20',
    path: (
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8.645 1.542C9.237 0.485 10.927 0.485 11.519 1.542L19.308 15.292C19.9 16.35 19.156 17.833 17.958 17.833H2.042C0.844 17.833 0.1 16.35 0.692 15.292L8.481 1.542ZM9.956 2.258C9.947 2.263 9.935 2.271 9.923 2.292L2.134 16.042C2.122 16.063 2.12 16.077 2.12 16.088C2.12 16.101 2.124 16.117 2.134 16.133C2.144 16.15 2.156 16.163 2.167 16.171C2.176 16.177 2.189 16.183 2.211 16.183H17.789C17.811 16.183 17.824 16.177 17.833 16.171C17.844 16.163 17.856 16.15 17.866 16.133C17.876 16.117 17.88 16.101 17.88 16.088C17.88 16.077 17.878 16.063 17.866 16.042L10.077 2.292C10.065 2.271 10.053 2.263 10.044 2.258C10.031 2.251 10.014 2.246 9.994 2.246C9.973 2.246 9.956 2.251 9.956 2.258Z"
            fill="currentColor"
        />
    ),
})

export const RequestIconHovered = createIcon({
    displayName: 'LoggedWorkIconHovered',
    viewBox: '0 0 20 20',
    path: (
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8.6276 2.37516C9.23703 1.31961 10.7606 1.31961 11.37 2.37516L19.3086 16.1252C19.918 17.1807 19.1562 18.5002 17.9374 18.5002H2.06024C0.841391 18.5002 0.0796125 17.1807 0.689037 16.1252L8.6276 2.37516Z"
            fill="#1D48C0"
        />
    ),
})

export const LogworkIcon = createIcon({
    displayName: 'LogworkIcon',
    viewBox: '0 0 20 20',
    path: (
        <>
            <g clipPath="url(#clip0_38211_214649)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.41797 9.99984C2.41797 5.81168 5.81314 2.4165 10.0013 2.4165C14.1895 2.4165 17.5846 5.81168 17.5846 9.99984C17.5846 14.188 14.1895 17.5832 10.0013 17.5832C5.81314 17.5832 2.41797 14.188 2.41797 9.99984ZM10.0013 0.916504C4.98472 0.916504 0.917969 4.98325 0.917969 9.99984C0.917969 15.0164 4.98472 19.0832 10.0013 19.0832C15.0179 19.0832 19.0846 15.0164 19.0846 9.99984C19.0846 4.98325 15.0179 0.916504 10.0013 0.916504ZM10.7513 5.83317C10.7513 5.41896 10.4155 5.08317 10.0013 5.08317C9.58709 5.08317 9.2513 5.41896 9.2513 5.83317V10.0756C9.2513 10.2785 9.33352 10.4728 9.47919 10.614L11.9792 13.0383C12.2766 13.3266 12.7514 13.3193 13.0397 13.0219C13.3281 12.7246 13.3208 12.2498 13.0234 11.9614L10.7513 9.75815V5.83317Z"
                    fill="currentColor"
                />
            </g>
            <defs>
                <clipPath id="clip0_38211_214649">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </>
    ),
})

export const LogworkIconHovered = createIcon({
    displayName: 'LogworkIconHovered',
    viewBox: '0 0 20 20',
    path: (
        <>
            <g clipPath="url(#clip0_23000_178566)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.0013 0.916504C4.98472 0.916504 0.917969 4.98325 0.917969 9.99984C0.917969 15.0164 4.98472 19.0832 10.0013 19.0832C15.0179 19.0832 19.0846 15.0164 19.0846 9.99984C19.0846 4.98325 15.0179 0.916504 10.0013 0.916504ZM10.7513 5.83317C10.7513 5.41895 10.4155 5.08317 10.0013 5.08317C9.58711 5.08317 9.25132 5.41895 9.25132 5.83317V10.0756C9.25132 10.2785 9.33354 10.4728 9.47921 10.614L11.9792 13.0383C12.2766 13.3266 12.7514 13.3193 13.0397 13.0219C13.3281 12.7246 13.3208 12.2498 13.0234 11.9614L10.7513 9.75815V5.83317Z"
                    fill="#1D48C0"
                />
            </g>
            <defs>
                <clipPath id="clip0_23000_178566">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </>
    ),
})

export const ProfileIcon = createIcon({
    displayName: 'ProfileIcon',
    viewBox: '0 0 20 20',
    path: (
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.58333 6.00016C6.58333 4.11319 8.11303 2.5835 10 2.5835C11.887 2.5835 13.4167 4.11319 13.4167 6.00016C13.4167 7.88714 11.887 9.41683 10 9.41683C8.11303 9.41683 6.58333 7.88714 6.58333 6.00016ZM10 1.0835C7.2846 1.0835 5.08333 3.28476 5.08333 6.00016C5.08333 7.90847 6.17051 9.56285 7.75929 10.3777C6.42215 10.736 5.18751 11.4128 4.18173 12.3627C2.63024 13.828 1.75 15.8249 1.75 17.9167C1.75 18.3309 2.08579 18.6667 2.5 18.6667C2.91421 18.6667 3.25 18.3309 3.25 17.9167C3.25 16.2512 3.95011 14.6447 5.21167 13.4533C6.47468 12.2604 8.1964 11.5833 10 11.5833C11.8036 11.5833 13.5253 12.2604 14.7883 13.4533C16.0499 14.6447 16.75 16.2512 16.75 17.9167C16.75 18.3309 17.0858 18.6667 17.5 18.6667C17.9142 18.6667 18.25 18.3309 18.25 17.9167C18.25 15.8249 17.3698 13.828 15.8183 12.3627C14.8125 11.4128 13.5779 10.736 12.2407 10.3777C13.8295 9.56285 14.9167 7.90847 14.9167 6.00016C14.9167 3.28476 12.7154 1.0835 10 1.0835Z"
            fill="currentColor"
        />
    ),
})

export const ProfileIconHovered = createIcon({
    displayName: 'ProfileIconHovered',
    viewBox: '0 0 20 20',
    path: (
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 1.2085C7.2846 1.2085 5.08333 3.40976 5.08333 6.12516C5.08333 8.03355 6.1706 9.68799 7.75948 10.5028C6.42227 10.861 5.18756 11.5379 4.18173 12.4879C2.63024 13.9532 1.75 15.95 1.75 18.0418C1.75 18.456 2.08579 18.7918 2.5 18.7918H17.5C17.9142 18.7918 18.25 18.456 18.25 18.0418C18.25 15.95 17.3698 13.9532 15.8183 12.4879C14.8124 11.5379 13.5777 10.861 12.2405 10.5028C13.8294 9.68799 14.9167 8.03355 14.9167 6.12516C14.9167 3.40976 12.7154 1.2085 10 1.2085Z"
            fill="#1D48C0"
        />
    ),
})

export const NewsIcon = createIcon({
    displayName: 'NewsIconHovered',
    viewBox: '0 0 20 20',
    path: (
        <>
            <g clipPath="url(#clip0_22974_177089)">
                <path
                    fillRule="evenodd"
                    clip-rule="evenodd"
                    d="M5.83464 3.6665C5.78861 3.6665 5.7513 3.70381 5.7513 3.74984V16.3332H15.8346C16.8011 16.3332 17.5846 15.5497 17.5846 14.5832V8.53629H13.3346C12.6903 8.53629 12.168 8.01396 12.168 7.36963V3.6665H5.83464ZM13.668 4.59932L16.3786 7.03629H13.668V4.59932ZM4.2513 16.3332V6.93573H2.41797V15.1022C2.41797 15.5461 2.63324 15.9624 2.99545 16.219C3.10035 16.2933 3.22572 16.3332 3.35427 16.3332H4.2513ZM4.2513 5.43573H1.94482C1.37771 5.43573 0.917969 5.89547 0.917969 6.46258V15.1022C0.917969 16.0326 1.36921 16.9052 2.12842 17.443C2.48679 17.6968 2.91511 17.8332 3.35427 17.8332H15.8346C17.6296 17.8332 19.0846 16.3781 19.0846 14.5832V7.78629C19.0846 7.57354 18.9943 7.3708 18.8361 7.22856L13.4194 2.35877C13.2817 2.23499 13.1031 2.1665 12.918 2.1665H5.83464C4.96018 2.1665 4.2513 2.87539 4.2513 3.74984V5.43573Z"
                    fill="currentColor"
                />
            </g>
            <defs>
                <clipPath id="clip0_22974_177089">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </>
    ),
})

export const NewsIconHovered = createIcon({
    displayName: 'NewsIconHovered',
    viewBox: '0 0 20 20',
    path: (
        <>
            <g clipPath="url(#clip0_22974_177091)">
                <path
                    fillRule="evenodd"
                    clip-rule="evenodd"
                    d="M17.5846 8.53629H19.0846V14.5832C19.0846 16.3781 17.6296 17.8332 15.8346 17.8332H3.35427C2.91511 17.8332 2.48679 17.6968 2.12842 17.443C1.36921 16.9052 0.917969 16.0326 0.917969 15.1022V6.46258C0.917969 5.89547 1.37771 5.43573 1.94482 5.43573H3.0013L3.0013 14.1665C3.0013 14.5117 3.28112 14.7915 3.6263 14.7915C3.97147 14.7915 4.2513 14.5117 4.2513 14.1665L4.2513 5.43573H4.2513V3.74984C4.2513 2.87539 4.96018 2.1665 5.83464 2.1665H12.168V3.6665V7.36963C12.168 8.01396 12.6903 8.53629 13.3346 8.53629H17.5846ZM13.668 2.58224L18.6222 7.03629H16.3786H13.668V4.59932V2.58224Z"
                    fill="#1D48C0"
                />
            </g>
            <defs>
                <clipPath id="clip0_22974_177091">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </>
    ),
})

export const AgreementsIcon = createIcon({
    displayName: 'AgreementsIcon',
    viewBox: '0 0 20 20',
    path: (
        <>
            <g clipPath="url(#clip0_22974_177201)">
                <path
                    fillRule="evenodd"
                    clip-rule="evenodd"
                    d="M4.16276 2.41797C3.6565 2.41797 3.24609 2.82837 3.24609 3.33464V16.668C3.24609 17.1742 3.6565 17.5846 4.16276 17.5846H15.8294C16.3357 17.5846 16.7461 17.1742 16.7461 16.668V11.6235L18.2461 10.1166V16.668C18.2461 18.0027 17.1641 19.0846 15.8294 19.0846H4.16276C2.82807 19.0846 1.74609 18.0027 1.74609 16.668V3.33464C1.74609 1.99995 2.82807 0.917969 4.16276 0.917969H11.6628C12.3439 0.917969 12.9924 1.21008 13.4437 1.72025L14.1444 2.51227L13.0841 3.57752L12.3203 2.71417C12.1537 2.52582 11.9142 2.41797 11.6628 2.41797H4.16276ZM16.5061 3.1009C16.05 2.64275 15.3084 2.64275 14.8524 3.1009L12.2444 5.7209L7.13999 10.849C6.92236 11.0676 6.80018 11.3635 6.80018 11.672V14.25H4.99642C4.58221 14.25 4.24642 14.5858 4.24642 15C4.24642 15.4142 4.58221 15.75 4.99642 15.75H7.96685H8.51792H10.8609C11.1713 15.75 11.4688 15.6263 11.6878 15.4064L16.7914 10.2791L19.402 7.65638C19.8551 7.20121 19.8551 6.46545 19.402 6.01028L16.5061 3.1009ZM8.51792 14.25H8.30018V11.8096L12.776 7.31312L15.2016 9.75L10.7224 14.25H8.51792ZM16.2599 8.68688L13.8342 6.25L15.6792 4.39645L18.1049 6.83333L16.2599 8.68688Z"
                    fill="currentColor"
                />
            </g>
            <defs>
                <clipPath id="clip0_22974_177201">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </>
    ),
})

export const AgreementsIconHovered = createIcon({
    displayName: 'AgreementsIconHovered',
    viewBox: '0 0 20 20',
    path: (
        <>
            <g clipPath="url(#clip0_22974_177203)">
                <path
                    fillRule="evenodd"
                    clip-rule="evenodd"
                    d="M4.16276 0.917969C2.82807 0.917969 1.74609 1.99995 1.74609 3.33464V16.668C1.74609 18.0027 2.82807 19.0846 4.16276 19.0846H15.8294C17.1641 19.0846 18.2461 18.0027 18.2461 16.668V10.7059L12.6324 16.3456C12.1632 16.8169 11.5256 17.0819 10.8606 17.0819H4.99609C3.84549 17.0819 2.91276 16.1492 2.91276 14.9986C2.91276 13.848 3.84549 12.9152 4.99609 12.9152H5.46652V11.6706C5.46652 11.0096 5.72833 10.3754 6.19467 9.90692L13.8672 2.19889L13.4437 1.72025C12.9924 1.21008 12.3439 0.917969 11.6628 0.917969H4.16276ZM14.852 3.10078C15.3081 2.64262 16.0497 2.64262 16.5057 3.10078L19.4017 6.01016C19.8548 6.46533 19.8548 7.20108 19.4017 7.65626L17.3202 9.74742L16.2595 8.68675L13.8339 6.24987L12.7732 5.18921L14.852 3.10078ZM15.2013 9.74987L16.262 10.8105L11.6874 15.4063C11.4685 15.6262 11.1709 15.7499 10.8606 15.7499H4.99609C4.58188 15.7499 4.24609 15.4141 4.24609 14.9999C4.24609 14.5857 4.58188 14.2499 4.99609 14.2499H6.79986V11.6719C6.79986 11.3634 6.92203 11.0675 7.13966 10.8488L11.715 6.25233L12.7757 7.31299L15.2013 9.74987Z"
                    fill="#1D48C0"
                />
            </g>
            <defs>
                <clipPath id="clip0_22974_177203">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </>
    ),
})

export const MenuBurgerIcon = createIcon({
    displayName: 'MenuBurgerIcon',
    viewBox: '0 0 20 20',
    path: (
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.75 5C1.75 4.58579 2.08579 4.25 2.5 4.25H17.5C17.9142 4.25 18.25 4.58579 18.25 5C18.25 5.41421 17.9142 5.75 17.5 5.75H2.5C2.08579 5.75 1.75 5.41421 1.75 5ZM1.75 10C1.75 9.58579 2.08579 9.25 2.5 9.25H17.5C17.9142 9.25 18.25 9.58579 18.25 10C18.25 10.4142 17.9142 10.75 17.5 10.75H2.5C2.08579 10.75 1.75 10.4142 1.75 10ZM2.5 14.25C2.08579 14.25 1.75 14.5858 1.75 15C1.75 15.4142 2.08579 15.75 2.5 15.75H17.5C17.9142 15.75 18.25 15.4142 18.25 15C18.25 14.5858 17.9142 14.25 17.5 14.25H2.5Z"
            fill="currentColor"
        />
    ),
})
