import { ChakraProvider } from '@chakra-ui/react'
import store from '@core/app/store/store'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import '../../index.css'
import theme from '../../theme.js'
import { PostsPageNew } from './PostsPageNew'
import { PostsPage } from '././PostsPage'
import { MantineProvider } from '@mantine/core'

import { IsApplyDialogProvider } from '@core/utils/contexts/ApplyDialogContext/ApplDialogContext'
import { ModalProvider } from '@core/utils/contexts/ModalContext/ModalContextProvider'
import { ThemeProvider } from '@core/utils/contexts/ThemeContext/ThemeContext'

import '@core/utils/i18n'
import { ParallaxProvider } from 'react-scroll-parallax'
import React from 'react'

import { generateRoutes, CustomURL } from '@core/utils/helpers/customeRouteBuilder'

const urls = generateRoutes('', '/jobs')

let routeCollection: object[] = []

urls.forEach((element: CustomURL) => {
    routeCollection.push({
        path: element.url,
        element: (
            <ThemeProvider>
                <IsApplyDialogProvider>
                    <MantineProvider>
                        {/*<PostsPage />*/}
                        <PostsPageNew />
                    </MantineProvider>
                </IsApplyDialogProvider>
            </ThemeProvider>
        ),
    })
})

const router = createBrowserRouter(routeCollection)

const app = document.getElementById('root')
const root = createRoot(app!)
root.render(
    <>
        <Provider store={store}>
            <ChakraProvider theme={theme}>
                <ModalProvider>
                    <ParallaxProvider scrollAxis="vertical">
                        <RouterProvider router={router} />
                    </ParallaxProvider>
                </ModalProvider>
            </ChakraProvider>
        </Provider>
    </>
)
