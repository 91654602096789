import { Box, Button, Container, Flex, Show, Spinner, useBoolean, useMediaQuery } from '@chakra-ui/react'
import { useGetPostsQuery } from '@core/app/api/postsApi'
import type { PostsAccordionAreasData } from '@core/app/types/globalApiType'
import type { PostsFilterAreaValue, PostsFilterCitiesItem, PostsFilterItemValue } from '@core/app/types/postsApiType'
import { Layout } from '@core/components/Layout/Layout'
import { TopTab } from '@page/posts/components/TopTab/TopTab'
import { EmptyState } from '@page/posts/components/EmptyState/EmptyState'
import PostListNew from '@page/posts/components/PostsList/PostListNew'
import PostSideBarNew from '@page/posts/components/Sidebar/PostSideBarNew'
import queryString from 'query-string'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useGetHeaderInfoQuery } from '@core/app/api/profileApi'

export const PostsPageNew = () => {
    const [isDesktop] = useMediaQuery('(min-width: 1230px)', {
        fallback: false,
    })
    const { data: headerInfo } = useGetHeaderInfoQuery({})
    const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined)
    // const [sortValue, setSortValue] = useState<'newest' | 'oldest'>('newest')
    const [sortValue, setSortValue] = useState<'newest' | 'oldest'>(() => {
        const storedValue = localStorage.getItem('sortValue') as 'newest' | 'oldest'
        return storedValue === 'newest' || storedValue === 'oldest' ? storedValue : 'newest'
    })
    const navigate = useNavigate()
    const [isSidebar, setIsSidebar] = useState<boolean>(false)
    const [showSidebar, setShowSidebar] = useBoolean(false)
    const isLogged = headerInfo && headerInfo.statusCode === 200 && headerInfo.data?.CandidateID
    const [showScrollButton, setShowScrollButton] = useState<boolean>(false)
    const isSignUpAvailable = headerInfo && headerInfo.statusCode === 200 && headerInfo?.menu?.signUp
    const [isLargeScreen] = useMediaQuery('(min-width: 1428px)')
    const [isBetween1408And1428] = useMediaQuery('(min-width: 1408px) and (max-width: 1427px)')
    const [areasData, setAreasData] = useState<PostsAccordionAreasData[]>([])

    const currentParams = queryString.parse(window.location.search, {
        arrayFormat: 'bracket',
    })

    const countryParams = currentParams.country
        ? Array.isArray(currentParams.country)
            ? [...new Set(currentParams.country.map(Number))]
            : [Number(currentParams.country)]
        : []

    const regionParams = currentParams.region
        ? Array.isArray(currentParams.region)
            ? [...new Set(currentParams.region.map(Number))]
            : [Number(currentParams.region)]
        : []

    const cityParams = currentParams.city
        ? Array.isArray(currentParams.city)
            ? [...new Set(currentParams.city.map(Number))]
            : [Number(currentParams.city)]
        : []

    const industryParams = currentParams.industry
        ? Array.isArray(currentParams.industry)
            ? [...new Set(currentParams.industry.map(Number))]
            : [Number(currentParams.industry)]
        : []

    const jobTypeParams = currentParams.jobType
        ? Array.isArray(currentParams.jobType)
            ? [...new Set(currentParams.jobType.map(Number))]
            : [Number(currentParams.jobType)]
        : []

    const workTypeParams = currentParams.workType
        ? Array.isArray(currentParams.workType)
            ? [...new Set(currentParams.workType.map(Number))]
            : [Number(currentParams.workType)]
        : []

    const officeParams = currentParams.office
        ? Array.isArray(currentParams.office)
            ? [...new Set(currentParams.office.map(Number))]
            : [Number(currentParams.office)]
        : []

    const {
        data: postsData,
        isFetching,
        isLoading,
    } = useGetPostsQuery({
        country: countryParams,
        region: regionParams,
        city: cityParams,
        industry: industryParams,
        jobType: jobTypeParams,
        workType: workTypeParams,
        office: officeParams,
        query: searchQuery,
        sort: sortValue,
    })

    const isVacanciesExist = postsData && postsData?.data && postsData.data?.metadata?.totalCount > 0
    const isLoadingContent = !isVacanciesExist && (isFetching || isLoading)

    useEffect(() => {
        const storedSortValue = localStorage.getItem(`sortValue`) as 'oldest' | 'newest' | undefined
        setSortValue(storedSortValue || 'newest')
    }, [])

    useEffect(() => {
        const searchString = queryString.stringify(
            {
                country: countryParams,
                region: regionParams,
                city: cityParams,
                industry: industryParams,
                jobType: jobTypeParams,
                workType: workTypeParams,
                office: officeParams,
                query: searchQuery,
                sort: sortValue,
            },
            { arrayFormat: 'bracket' }
        )
        localStorage.setItem('jobs_search_string', searchString)
        navigate({
            pathname: window.location.pathname,
            search: searchString,
        })
    }, [searchQuery, sortValue])

    useEffect(() => {
        if (postsData?.data?.filtersSettings) {
            const { area, industry, jobType, office, workType } = postsData?.data?.filtersSettings
            if (area || industry || jobType || office || workType) {
                setIsSidebar(true)
            }
        }
    }, [postsData])

    useEffect(() => {
        const updatedParams = JSON.parse(JSON.stringify(currentParams))
        const res: PostsAccordionAreasData[] = Object.values(postsData?.data?.filters?.area || {}).map(
            (area: PostsFilterAreaValue) => {
                const someRegionChecked = Object.values(area.regions || {}).some((region: PostsFilterItemValue) => {
                    return Object.values(region.cities || {}).some((city: PostsFilterCitiesItem) => city.selected)
                })
                const isCountryUrl = updatedParams.country?.includes(String(area.id))
                return {
                    ...area,
                    // expanded: true,
                    expanded: countryParams.includes(area.id),
                    selected: area.selected || someRegionChecked || isCountryUrl,
                    regions: Object.values(area.regions || {}).map((region: PostsFilterItemValue) => {
                        const someCityChecked = Object.values(region.cities || {}).some(
                            (city: PostsFilterCitiesItem) => city.selected
                        )
                        const isRegionUrl = updatedParams.region?.includes(String(region.id))
                        return {
                            ...region,
                            // expanded: false,
                            expanded: regionParams.includes(region.id),
                            selected: region.selected || someCityChecked || isRegionUrl,
                            cities: Object.values(region.cities || {}).map((city: PostsFilterCitiesItem) => {
                                return {
                                    ...city,
                                    // expanded: false,
                                    selected: city.selected,
                                }
                            }),
                        }
                    }),
                }
            }
        )
        setAreasData(res)
    }, [postsData])

    const reset = () => {
        setSearchQuery(undefined)
        setSortValue('newest')
        setAreasData((prevState) => {
            return prevState.map((area) => ({
                ...area,
                selected: false,
                expanded: false,
                regions: area.regions.map((region) => ({
                    ...region,
                    selected: false,
                    expanded: false,
                    cities: region.cities.map((city) => ({
                        ...city,
                        selected: false,
                    })),
                })),
            }))
        })
        navigate({
            pathname: window.location.pathname,
            search: '',
        })
    }
    const handleSelect = (
        id: number | string,
        type: 'country' | 'region' | 'city' | 'industry' | 'jobType' | 'workType' | 'office',
        value: boolean
    ) => {
        const numberId = Number(id)
        const updatedParams = {
            country: countryParams,
            region: regionParams,
            city: cityParams,
            industry: industryParams,
            jobType: jobTypeParams,
            workType: workTypeParams,
            office: officeParams,
            query: currentParams.query,
            sort: sortValue,
        }

        switch (type) {
            case 'country':
                if (value) {
                    updatedParams.country = [...new Set([...updatedParams.country, numberId])]
                    // areasData.forEach((area) => {
                    //     if (area.id === numberId) {
                    //         updatedParams.region = [
                    //             ...new Set([
                    //                 ...updatedParams.region,
                    //                 ...area.regions.map((region) => {
                    //                     region.cities.forEach((city) => {
                    //                         updatedParams.city = [...new Set([...updatedParams.city, city.id])]
                    //                     })
                    //                     return region.id
                    //                 }),
                    //             ]),
                    //         ]
                    //     }
                    // })
                } else {
                    updatedParams.country = updatedParams.country.filter((item) => item !== numberId)
                    areasData.forEach((area) => {
                        if (area.id === numberId) {
                            updatedParams.region = updatedParams.region.filter(
                                (item) => !area.regions.some((region) => region.id === item)
                            )
                            updatedParams.city = updatedParams.city.filter(
                                (item) => !area.regions.some((region) => region.cities.some((city) => city.id === item))
                            )
                        }
                    })
                }
                break
            case 'region':
                if (value) {
                    updatedParams.region = [...new Set([...updatedParams.region, numberId])]
                    areasData.forEach((area) => {
                        area.regions.forEach((region) => {
                            if (region.id === numberId) {
                                updatedParams.country = [...new Set([...updatedParams.country, area.id])]
                                // updatedParams.city = [
                                //     ...new Set([...updatedParams.city, ...region.cities.map((city) => city.id)]),
                                // ]
                            }
                        })
                    })
                } else {
                    updatedParams.region = updatedParams.region.filter((item) => item !== numberId)
                    areasData.forEach((area) => {
                        area.regions.forEach((region) => {
                            if (region.id === numberId) {
                                updatedParams.city = updatedParams.city.filter(
                                    (item) => !region.cities.some((city) => city.id === item)
                                )
                            }
                        })
                    })
                    if (areasData.length === 1 && updatedParams.region.length === 0) {
                        updatedParams.country = []
                    }
                }
                break
            case 'city':
                if (value) {
                    updatedParams.city = [...new Set([...updatedParams.city, numberId])]
                    areasData.forEach((area) => {
                        area.regions.forEach((region) => {
                            region.cities.forEach((city) => {
                                if (city.id === numberId) {
                                    updatedParams.region = [...new Set([...updatedParams.region, region.id])]
                                    updatedParams.country = [...new Set([...updatedParams.country, area.id])]
                                }
                            })
                        })
                    })
                } else {
                    updatedParams.city = updatedParams.city.filter((item) => item !== numberId)
                }
                break
            case 'industry':
                if (value) {
                    updatedParams.industry = [...new Set([...updatedParams.industry, numberId])]
                } else {
                    updatedParams.industry = updatedParams.industry.filter((item) => item !== numberId)
                }
                break
            case 'jobType':
                if (value) {
                    updatedParams.jobType = [...new Set([...updatedParams.jobType, numberId])]
                } else {
                    updatedParams.jobType = updatedParams.jobType.filter((item) => item !== numberId)
                }
                break
            case 'workType':
                if (value) {
                    updatedParams.workType = [...new Set([...updatedParams.workType, numberId])]
                } else {
                    updatedParams.workType = updatedParams.workType.filter((item) => item !== numberId)
                }
                break
            case 'office':
                if (value) {
                    updatedParams.office = [...new Set([...updatedParams.office, numberId])]
                } else {
                    updatedParams.office = updatedParams.office.filter((item) => item !== numberId)
                }
                break
        }

        navigate({
            pathname: window.location.pathname,
            search: queryString.stringify(updatedParams, { arrayFormat: 'bracket' }),
        })
    }
    const handleSortChange = (value: 'newest' | 'oldest') => {
        setSortValue(value)
        const updatedParams = JSON.parse(JSON.stringify(currentParams))
        updatedParams.sort = value
        navigate({
            pathname: window.location.pathname,
            search: queryString.stringify(updatedParams, { arrayFormat: 'bracket' }),
        })
    }

    if (isLoadingContent) {
        return (
            <Flex w={'full'} h={'100vh'} justifyContent={'center'} alignItems={'center'}>
                <Spinner
                    size={'xl'}
                    thickness={'4px'}
                    speed={'0.65s'}
                    emptyColor={'formElements.borderDefault'}
                    color={'state.primary'}
                />
            </Flex>
        )
    }

    if (!isVacanciesExist && !isLoadingContent) {
        return (
            <Layout useContainer={false} useMobileHeader={true} showFooter={true}>
                <EmptyState isDesktop={isDesktop} isLogged={isLogged} isSignUpAvailable={isSignUpAvailable} />
            </Layout>
        )
    }

    return (
        <Layout useContainer={false} fixedHeader={true} showFooter={(isDesktop && isVacanciesExist) || !isDesktop}>
            <Box height="84px" position={'relative'}></Box>
            <Show below={'xl'}>
                <TopTab
                    data={postsData?.data?.job_posts}
                    settings={postsData?.data?.filtersSettings}
                    searchQuery={(value) => setSearchQuery(value.trim() ? value : undefined)}
                    open={() => setShowSidebar.toggle()}
                    onReset={() => reset()}
                    countryParams={countryParams}
                    regionParams={regionParams}
                    cityParams={cityParams}
                    industryParams={industryParams}
                    jobTypeParams={jobTypeParams}
                    workTypeParams={workTypeParams}
                    officeParams={officeParams}
                    sortValue={sortValue}
                />
            </Show>
            <Container
                maxW={{ base: 'full', xl: 'container.xl' }}
                mx={{ base: 0, xl: 'auto' }}
                px={{ base: 0, xl: 'auto' }}
                pt={'0px'}
                pb={'30px'}
                className={'posts-page-main-container'}
            >
                <Box as={'section'} py={{ base: 4, xl: 8 }}>
                    <Flex
                        className={'Posts-flex'}
                        justifyContent={isSidebar ? 'space-between' : 'center'}
                        alignItems={'flex-start'}
                    >
                        {((isDesktop && isSidebar) || (!isDesktop && isSidebar)) && (
                            <PostSideBarNew
                                postsData={postsData}
                                areasData={areasData}
                                onSelect={handleSelect}
                                reset={() => reset()}
                                isOpened={showSidebar}
                                closeModal={() => setShowSidebar.off()}
                                searchQuery={searchQuery || ''}
                                setSearchQuery={(value) => setSearchQuery(value.trim() ? value : undefined)}
                                isLoading={isFetching || isLoading}
                                countryParams={countryParams}
                                regionParams={regionParams}
                                cityParams={cityParams}
                                industryParams={industryParams}
                                jobTypeParams={jobTypeParams}
                                workTypeParams={workTypeParams}
                                officeParams={officeParams}
                                sortValue={sortValue}
                            />
                        )}
                        <PostListNew
                            data={postsData?.data?.job_posts}
                            settings={postsData?.data?.filtersSettings}
                            reset={() => reset()}
                            onSortChange={handleSortChange}
                            defaultView={postsData?.data?.defaultView || 0}
                            candidateID={headerInfo?.data?.CandidateID || 0}
                            isLoading={isFetching || isLoading}
                            sortValue={sortValue}
                        />
                    </Flex>
                </Box>
                {showScrollButton && (
                    <Button
                        className={'back-to-top-button'}
                        position="sticky"
                        bottom={isDesktop ? '40px' : '30px'}
                        marginLeft="auto"
                        marginRight={isLargeScreen ? '-80px' : isBetween1408And1428 ? '-60px' : '30px'}
                        display={'flex'}
                        gap={'10px'}
                        width="44px"
                        height="44px"
                        borderRadius="50%"
                        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                    >
                        <i className="fa-regular fa-arrow-up"></i>
                    </Button>
                )}
            </Container>
        </Layout>
    )
}
