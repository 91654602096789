function decodeHtmlEntitiesRecursive(text: string | null): string {
    if (!text) return ''
    let decoded = text
    let prev
    const textarea = document.createElement('textarea')

    do {
        prev = decoded
        textarea.innerHTML = decoded
        decoded = textarea.value
    } while (decoded !== prev)

    return decoded
}

export default decodeHtmlEntitiesRecursive
