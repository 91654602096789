import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Flex,
    HStack,
    Image,
    Text,
} from '@chakra-ui/react'
import type { PostsAccordionAreasData } from '@core/app/types/globalApiType'
import { type FilterSettings, PostsFilterCities, type PostsFilters } from '@core/app/types/postsApiType'
import { CheckboxItem } from '@page/posts/components/Checkbox/Checkbox'
import React, { useEffect } from 'react'

interface Props {
    areasData: PostsAccordionAreasData[]
    filters: PostsFilters | undefined
    onSelect: (
        id: string | number,
        type: 'country' | 'region' | 'city' | 'industry' | 'jobType' | 'workType' | 'office',
        value: boolean
    ) => void
    countryParams: number[]
    settings: FilterSettings
    BackgroundText: string | null
    regionParams: number[]
    cityParams: number[]
}

function AreasAccordion({
    areasData,
    filters,
    onSelect,
    countryParams,
    settings,
    BackgroundText,
    regionParams,
    cityParams,
}: Props) {
    const [expandedAreas, setExpandedAreas] = React.useState(
        areasData.map((area, areaIndex) => {
            return {
                id: area.id,
                // expanded: false,
                // expanded: countryParams.includes(area.id),
                expanded: area.expanded,
                index: areaIndex,
                regions: area.regions.map((region, regIdx) => {
                    return {
                        id: region.id,
                        // expanded: false,
                        // expanded: regionParams.includes(region.id),
                        expanded: region.expanded,
                        index: regIdx,
                        cities: region.cities.map((city, cityIdx) => {
                            return {
                                id: city.id,
                                expanded: false,
                                index: cityIdx,
                            }
                        }),
                    }
                }),
            }
        })
    )

    useEffect(() => {
        setExpandedAreas(
            areasData.map((area, areaIndex) => {
                return {
                    id: area.id,
                    // expanded: false,
                    // expanded: countryParams.includes(area.id),
                    expanded: area.expanded,
                    index: areaIndex,
                    regions: area.regions.map((region, regIdx) => {
                        return {
                            id: region.id,
                            // expanded: false,
                            // expanded: regionParams.includes(region.id),
                            expanded: region.expanded,
                            index: regIdx,
                            cities: region.cities.map((city, cityIdx) => {
                                return {
                                    id: city.id,
                                    expanded: false,
                                    index: cityIdx,
                                }
                            }),
                        }
                    }),
                }
            })
        )
    }, [areasData])

    const isSingleCountry = areasData.length === 1
    const isSingleEmptyCountry = areasData.length === 1 && areasData[0].regions.length === 0
    const onSelectItem = (
        id: string | number,
        type: 'country' | 'region' | 'city' | 'industry' | 'jobType' | 'workType' | 'office',
        value: boolean,
        areaIdx?: number,
        regionIdx?: number
    ) => {
        onSelect(id, type, value)
        if (value) {
            setExpandedAreas((prev) => {
                return prev.map((area) => {
                    return {
                        ...area,
                        expanded: areaIdx === area.index || area.expanded,
                        regions: area.regions.map((region) => {
                            return {
                                ...region,
                                expanded: (areaIdx === area.index && regionIdx === region.index) || region.expanded,
                                cities: region.cities.map((city) => {
                                    return {
                                        ...city,
                                        // expanded: true,
                                    }
                                }),
                            }
                        }),
                    }
                })
            })
        }
    }

    if (isSingleEmptyCountry) {
        return null
    }

    if (isSingleCountry) {
        return (
            <Box>
                {filters?.area &&
                    areasData.map((area: PostsAccordionAreasData, areaIndex) => (
                        <Accordion
                            pb={0}
                            pt={0}
                            px={0}
                            pl={0}
                            index={expandedAreas.reduce((accAreas, currentArea) => {
                                if (currentArea.index === areaIndex) {
                                    return currentArea.regions.reduce((accRegions, currentRegion, idx) => {
                                        if (currentRegion.expanded) {
                                            accRegions.push(idx)
                                        }
                                        return accRegions
                                    }, [] as number[])
                                }
                                return accAreas
                            }, [] as number[])} // TODO: regions
                            allowMultiple
                            onChange={(indexes: number[]) => {
                                setExpandedAreas((prev) => {
                                    return prev.map((area, index) => {
                                        if (index === areaIndex) {
                                            return {
                                                ...area,
                                                regions: area.regions.map((region, regIdx) => {
                                                    return {
                                                        ...region,
                                                        expanded: indexes.includes(regIdx),
                                                    }
                                                }),
                                            }
                                        }
                                        return area
                                    })
                                })
                            }}
                        >
                            {area.regions.map((region, regionIndex) => (
                                <AccordionItem border="none" key={region.id}>
                                    {region.cities.length ? (
                                        <AccordionButton
                                            px="0"
                                            py={'7px'}
                                            _hover={{
                                                bg: 'transparent',
                                            }}
                                            // onClick={() =>
                                            //     toggleRegionAccordion(
                                            //         index,
                                            //         regionIndex
                                            //     )
                                            // } // TODO: fix this
                                        >
                                            <HStack
                                                w="full"
                                                // justifyContent="space-between"
                                            >
                                                <CheckboxItem // TODO: Region
                                                    id={region.id}
                                                    name={region.name}
                                                    size={'lg'}
                                                    onSelect={(
                                                        id: string | number,
                                                        type:
                                                            | 'country'
                                                            | 'region'
                                                            | 'city'
                                                            | 'industry'
                                                            | 'jobType'
                                                            | 'workType'
                                                            | 'office',
                                                        value: boolean
                                                    ) => onSelectItem(id, type, value, areaIndex, regionIndex)}
                                                    selected={regionParams.includes(region.id)}
                                                    type="region"
                                                    fontSize={'14px'}
                                                    fontWeight={'500'}
                                                />
                                                <Text
                                                    fontSize="14px"
                                                    fontWeight="medium"
                                                    color={
                                                        settings?.boxStyle
                                                            ? 'text.thirdly'
                                                            : `#${BackgroundText ? BackgroundText : '747474'}`
                                                    }
                                                    pr={1.5}
                                                >
                                                    ({region.totalCount})
                                                </Text>
                                            </HStack>
                                            {region.cities.length > 0 && <AccordionIcon />}
                                        </AccordionButton>
                                    ) : (
                                        <HStack
                                            w="full"
                                            py={'7px'}
                                            // justifyContent="space-between"
                                        >
                                            <CheckboxItem // TODO: Region
                                                id={region.id}
                                                name={region.name}
                                                onSelect={(
                                                    id: string | number,
                                                    type:
                                                        | 'country'
                                                        | 'region'
                                                        | 'city'
                                                        | 'industry'
                                                        | 'jobType'
                                                        | 'workType'
                                                        | 'office',
                                                    value: boolean
                                                ) => onSelectItem(id, type, value, areaIndex, regionIndex)}
                                                selected={regionParams.includes(region.id)}
                                                type="region"
                                                size={'lg'}
                                                fontSize={'14px'}
                                                fontWeight={'500'}
                                            />
                                            <Text
                                                fontSize="14px"
                                                fontWeight="medium"
                                                color={
                                                    settings?.boxStyle
                                                        ? 'text.thirdly'
                                                        : `#${BackgroundText ? BackgroundText : '747474'}`
                                                }
                                                pr={1.5}
                                            >
                                                ({region.totalCount})
                                            </Text>
                                        </HStack>
                                    )}
                                    {region.cities.length > 0 && (
                                        <AccordionPanel pb={0} pt={0} px={0} pl={6}>
                                            <Flex
                                                flexDirection="column"
                                                // gap={'5px'}
                                                // py={2}
                                                alignItems="flex-start"
                                            >
                                                {region.cities.map((city: PostsFilterCities[0], cityIndex) => (
                                                    <HStack
                                                        w="full"
                                                        key={city.id}
                                                        py={'7px'}
                                                        // justifyContent="space-between"
                                                    >
                                                        <CheckboxItem // TODO: City
                                                            id={city.id}
                                                            name={city.name}
                                                            onSelect={onSelectItem}
                                                            selected={cityParams.includes(city.id)}
                                                            type="city"
                                                            size={'lg'}
                                                            fontSize={'14px'}
                                                            fontWeight={'500'}
                                                        />
                                                        <Text
                                                            color={
                                                                settings?.boxStyle
                                                                    ? 'text.thirdly'
                                                                    : `#${BackgroundText ? BackgroundText : '747474'}`
                                                            }
                                                            pr={1.5}
                                                            fontSize={'14px'}
                                                            fontWeight={'500'}
                                                        >
                                                            ({city.totalCount})
                                                        </Text>
                                                    </HStack>
                                                ))}
                                            </Flex>
                                        </AccordionPanel>
                                    )}
                                </AccordionItem>
                            ))}
                        </Accordion>
                    ))}
            </Box>
        )
    }

    return (
        <Accordion
            index={expandedAreas.reduce((acc, current, idx) => {
                if (current.expanded) {
                    acc.push(idx)
                }
                return acc
            }, [] as number[])}
            // defaultIndex={areasData.map((area, index) => index)}
            allowMultiple
            onChange={(indexes: number[]) => {
                setExpandedAreas((prev) => {
                    return prev.map((area, index) => {
                        return {
                            ...area,
                            expanded: indexes.includes(index),
                        }
                    })
                })
            }}
        >
            {filters?.area &&
                areasData.map((area: PostsAccordionAreasData, areaIndex) => (
                    <AccordionItem border="none" key={area.id}>
                        <AccordionButton
                            px="0"
                            py={'7px'}
                            _hover={{ bg: 'transparent' }}
                            // onClick={() =>
                            //     setExpandedAreas((prev) => {
                            //         return prev.filter((_, i) => i === index)
                            //     })
                            // }
                        >
                            <HStack
                                w="full"
                                // justifyContent="space-between"
                            >
                                <CheckboxItem // TODO: Country
                                    id={area.id}
                                    name={area.name}
                                    onSelect={(
                                        id: string | number,
                                        type:
                                            | 'country'
                                            | 'region'
                                            | 'city'
                                            | 'industry'
                                            | 'jobType'
                                            | 'workType'
                                            | 'office',
                                        value: boolean
                                    ) => onSelectItem(id, type, value, areaIndex)}
                                    selected={countryParams.includes(area.id)}
                                    type="country"
                                    size={'lg'}
                                    fontSize={'14px'}
                                    fontWeight={'500'}
                                >
                                    <Image
                                        src={`${process.env.ENV === 'local' ? process.env.API_URL : window.location.origin}/${area.flag}`}
                                        fallbackSrc={''}
                                        maxW={4}
                                    />
                                </CheckboxItem>

                                <Text
                                    fontSize={'12px'}
                                    fontWeight={'500'}
                                    color={
                                        settings?.boxStyle
                                            ? 'text.thirdly'
                                            : `#${BackgroundText ? BackgroundText : '747474'}`
                                    }
                                    pr={1.5}
                                >
                                    ({area.totalCount})
                                </Text>
                            </HStack>
                            {area.regions.length > 0 && <AccordionIcon />}
                        </AccordionButton>
                        <AccordionPanel pb={0} pt={0} px={0} pl={6}>
                            <Accordion
                                key={area.id}
                                index={expandedAreas.reduce((accAreas, currentArea) => {
                                    if (currentArea.index === areaIndex) {
                                        return currentArea.regions.reduce((accRegions, currentRegion, idx) => {
                                            if (currentRegion.expanded) {
                                                accRegions.push(idx)
                                            }
                                            return accRegions
                                        }, [] as number[])
                                    }
                                    return accAreas
                                }, [] as number[])} // TODO: regions
                                allowMultiple
                                onChange={(indexs: number[]) => {
                                    setExpandedAreas((prev) => {
                                        return prev.map((area, index) => {
                                            if (index === areaIndex) {
                                                return {
                                                    ...area,
                                                    regions: area.regions.map((region, regIdx) => {
                                                        return {
                                                            ...region,
                                                            expanded: indexs.includes(regIdx),
                                                        }
                                                    }),
                                                }
                                            }
                                            return area
                                        })
                                    })
                                }}
                            >
                                {area.regions.map((region, regionIndex) => (
                                    <AccordionItem border="none" key={region.id}>
                                        <AccordionButton
                                            px="0"
                                            py={'7px'}
                                            _hover={{
                                                bg: 'transparent',
                                            }}
                                            // onClick={() =>
                                            //     toggleRegionAccordion(
                                            //         index,
                                            //         regionIndex
                                            //     )
                                            // } // TODO: fix this
                                        >
                                            <HStack
                                                w="full"
                                                // justifyContent="space-between"
                                            >
                                                <CheckboxItem // TODO: Region
                                                    id={region.id}
                                                    name={region.name}
                                                    onSelect={(
                                                        id: string | number,
                                                        type:
                                                            | 'country'
                                                            | 'region'
                                                            | 'city'
                                                            | 'industry'
                                                            | 'jobType'
                                                            | 'workType'
                                                            | 'office',
                                                        value: boolean
                                                    ) => onSelectItem(id, type, value, areaIndex, regionIndex)}
                                                    selected={regionParams.includes(region.id)}
                                                    type="region"
                                                    size={'lg'}
                                                    fontSize={'14px'}
                                                    fontWeight={'500'}
                                                />
                                                <Text
                                                    fontSize="14px"
                                                    fontWeight="medium"
                                                    color={
                                                        settings?.boxStyle
                                                            ? 'text.thirdly'
                                                            : `#${BackgroundText ? BackgroundText : '747474'}`
                                                    }
                                                    pr={1.5}
                                                >
                                                    ({region.totalCount})
                                                </Text>
                                            </HStack>
                                            {region.cities.length > 0 && <AccordionIcon />}
                                        </AccordionButton>
                                        {region.cities.length > 0 && (
                                            <AccordionPanel pb={0} pt={0} px={0} pl={6}>
                                                <Flex
                                                    flexDirection="column"
                                                    // gap={'5px'}
                                                    // py={2}
                                                    alignItems="flex-start"
                                                >
                                                    {region.cities.map((city: PostsFilterCities[0], cityIndex) => (
                                                        <HStack
                                                            w="full"
                                                            key={city.id}
                                                            py={'7px'}
                                                            // justifyContent="space-between"
                                                        >
                                                            <CheckboxItem // TODO: City
                                                                id={city.id}
                                                                name={city.name}
                                                                onSelect={onSelectItem}
                                                                selected={cityParams.includes(city.id)}
                                                                type="city"
                                                                size={'lg'}
                                                                fontSize={'14px'}
                                                                fontWeight={'500'}
                                                            />
                                                            <Text
                                                                color={
                                                                    settings?.boxStyle
                                                                        ? 'text.thirdly'
                                                                        : `#${BackgroundText ? BackgroundText : '747474'}`
                                                                }
                                                                pr={1.5}
                                                                fontSize={'14px'}
                                                                fontWeight={'500'}
                                                            >
                                                                ({city.totalCount})
                                                            </Text>
                                                        </HStack>
                                                    ))}
                                                </Flex>
                                            </AccordionPanel>
                                        )}
                                    </AccordionItem>
                                ))}
                            </Accordion>
                        </AccordionPanel>
                    </AccordionItem>
                ))}
        </Accordion>
    )
}

export default AreasAccordion
